var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "footer-client" }, [
        _c("div", { staticClass: "wn mc" }, [
          _c("div", { staticClass: "ac intro-title" }, [_vm._v("合作伙伴")]),
          _vm._v(" "),
          _c("div", { staticClass: "ac" }, [
            _c("div", { staticClass: "footer-client-col ico ico-client1" }),
            _vm._v(" "),
            _c("div", { staticClass: "footer-client-col ico ico-client2" }),
            _vm._v(" "),
            _c("div", { staticClass: "footer-client-col ico ico-client3" }),
            _vm._v(" "),
            _c("div", { staticClass: "footer-client-col ico ico-client4" }),
            _vm._v(" "),
            _c("div", { staticClass: "footer-client-col ico ico-client5" }),
            _vm._v(" "),
            _c("div", { staticClass: "footer-client-col ico ico-client6" }),
            _vm._v(" "),
            _c("div", { staticClass: "footer-client-col ico ico-client7" }),
            _vm._v(" "),
            _c("div", { staticClass: "footer-client-col ico ico-client8" }),
            _vm._v(" "),
            _c("div", { staticClass: "footer-client-col ico ico-client9" }),
            _vm._v(" "),
            _c("div", { staticClass: "footer-client-col ico ico-client10" }),
            _vm._v(" "),
            _c("div", { staticClass: "footer-client-col ico ico-client11" }),
            _vm._v(" "),
            _c("div", { staticClass: "footer-client-col ico ico-client12" }),
            _vm._v(" "),
            _c("div", { staticClass: "footer-client-col ico ico-client13" }),
            _vm._v(" "),
            _c("div", { staticClass: "footer-client-col ico ico-client14" }),
            _vm._v(" "),
            _c("div", { staticClass: "footer-client-col ico ico-client15" }),
            _vm._v(" "),
            _c("div", { staticClass: "footer-client-col ico ico-client16" }),
            _vm._v(" "),
            _c("div", { staticClass: "footer-client-col ico ico-client17" }),
            _vm._v(" "),
            _c("div", { staticClass: "footer-client-col ico ico-client18" })
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "footer-copy" }, [
        _c("div", { staticClass: "wn mc" }, [
          _c("div", { staticClass: "mln mrn footer-copy-block" }, [
            _c("div", { staticClass: "footer-copy-left" }, [
              _vm._v("© Copyright "),
              _c("span", { attrs: { id: "footer-copy-date-y" } }),
              _vm._v(" All Rights Reserved. 保留所有权利")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "footer-copy-right" }, [
              _c(
                "a",
                {
                  attrs: { href: "https://beian.miit.gov.cn", target: "_blank" }
                },
                [_vm._v("京ICP备12034318号-10")]
              ),
              _vm._v(" |\r\n                    "),
              _c("a", { attrs: { href: "/case.php" } }, [_vm._v("项目案例")]),
              _vm._v(" |\r\n                    "),
              _c("a", { attrs: { href: "/about.php" } }, [_vm._v("关于我们")])
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }