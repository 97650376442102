// import '../../css/style.css';
import Header from '../component/header.vue';
import Footer from '../component/footer.vue';

new Vue({
    el: '#vue-header',
    render: h => h(Header)
});

new Vue({
    el: '#vue-footer',
    render: f => f(Footer)
});