var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "wn mc" }, [
        _c("div", { staticClass: "header-nav" }, [
          _c("a", { staticClass: "logo", attrs: { href: "/" } }),
          _vm._v(" "),
          _c("span", { staticClass: "header-menu" }, [
            _c("img", {
              attrs: {
                src: "/static/image/menu.png",
                width: "22",
                height: "20"
              }
            })
          ]),
          _vm._v(" "),
          _c("ul", { staticClass: "header-ul" }, [
            _c("div", { staticClass: "header-ico" }),
            _vm._v(" "),
            _c("li", [
              _c("a", { attrs: { id: "menu-index", href: "/" } }, [
                _vm._v("首页")
              ])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("a", { attrs: { id: "menu-case", href: "/case.php" } }, [
                _vm._v("项目案例")
              ])
            ]),
            _vm._v(" "),
            _c("li", [
              _c("a", { attrs: { id: "menu-about", href: "/about.php" } }, [
                _vm._v("关于我们")
              ])
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }