<template>
<div>
    <div class="footer-client">
        <div class="wn mc">
            <div class="ac intro-title">合作伙伴</div>
            <div class="ac">
                <div class="footer-client-col ico ico-client1"></div>
                <div class="footer-client-col ico ico-client2"></div>
                <div class="footer-client-col ico ico-client3"></div>
                <div class="footer-client-col ico ico-client4"></div>
                <div class="footer-client-col ico ico-client5"></div>
                <div class="footer-client-col ico ico-client6"></div>
                <div class="footer-client-col ico ico-client7"></div>
                <div class="footer-client-col ico ico-client8"></div>
                <div class="footer-client-col ico ico-client9"></div>
                <div class="footer-client-col ico ico-client10"></div>
                <div class="footer-client-col ico ico-client11"></div>
                <div class="footer-client-col ico ico-client12"></div>
                <div class="footer-client-col ico ico-client13"></div>
                <div class="footer-client-col ico ico-client14"></div>
                <div class="footer-client-col ico ico-client15"></div>
                <div class="footer-client-col ico ico-client16"></div>
                <div class="footer-client-col ico ico-client17"></div>
                <div class="footer-client-col ico ico-client18"></div>
                <!-- <div class="footer-client-col ico ico-client19"></div>
                <div class="footer-client-col ico ico-client20"></div> -->
            </div>
        </div>
    </div>

    <div class="footer-copy">
        <div class="wn mc">
            <div class="mln mrn footer-copy-block">
                <div class="footer-copy-left">&copy;&nbsp;Copyright <span id="footer-copy-date-y"></span> All Rights Reserved. 保留所有权利</div>
                <div class="footer-copy-right">
                    <a href="https://beian.miit.gov.cn" target="_blank">京ICP备12034318号-10</a>&nbsp;|
                    <a href="/case.php">项目案例</a>&nbsp;|
                    <a href="/about.php">关于我们</a>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>

$.post('/index.php?do=getInfo', function(r) {
    if(r.error == 0) {
        $('#footer-copy-date-y').html(r.date_y);
    }
}, 'json');

$(document).ready(function() {
    if(screen.width < 768) {
        $('.header-menu').click(function() {
            if($('.header-ul').is(':hidden')) {
                $('.header-ul').slideDown();
            } else {
                $('.header-ul').slideUp();
            }
        }); 

        $('body').click(function(e) {
            if(!$(e.target).closest('.header-ul, .header-menu').length) {
                $(".header-ul").slideUp();
            }
        });
    }
});

</script>