<template>
<div class="main">
    <div class="index-hero">
        <div class="index-hero-block">
            <h1 class="index-hero-h1">信息化服务提供商</h1>
            <h2 class="index-hero-h2">专业&nbsp;&nbsp;安全&nbsp;&nbsp;高效</h2>
        </div>
    </div>

    <div class="intro-block">
        <div class="wn mc">
            <div class="item intro">
                <div class="ac intro-title intro-title2">我们的服务</div>
                <div class="item-boxes services-item">
                    <i class="ico2 ico2-1"></i>
                    <h4>人工智能</h4>
                    <p>数据更智能、业务才更智能</p>
                </div>
                <div class="item-boxes services-item">
                    <i class="ico2 ico2-3"></i>
                    <h4>金融科技</h4>
                    <p>擅长金融领域科技研发与测试</p>
                </div>
                <div class="item-boxes services-item">
                    <i class="ico2 ico2-2"></i>
                    <h4>大数据</h4>
                    <p>海量数据分析和挖掘能力</p>
                </div>
                <div class="item-boxes services-item">
                    <i class="ico2 ico2-4"></i>
                    <h4>云计算</h4>
                    <p>高灵活性、可扩展性和高性价比</p>
                </div>
                <div class="item-boxes services-item">
                    <i class="ico2 ico2-5"></i>
                    <h4>软件开发</h4>
                    <p>技术领先、一站式软件定制开发服务</p>
                </div>
                <div class="item-boxes services-item">
                    <i class="ico2 ico2-6"></i>
                    <h4>数据安全</h4>
                    <p>数据加密，安全可靠</p>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
$('#menu-index').addClass('active');
</script>