var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "main" }, [
      _c("div", { staticClass: "index-hero" }, [
        _c("div", { staticClass: "index-hero-block" }, [
          _c("h1", { staticClass: "index-hero-h1" }, [
            _vm._v("信息化服务提供商")
          ]),
          _vm._v(" "),
          _c("h2", { staticClass: "index-hero-h2" }, [
            _vm._v("专业  安全  高效")
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "intro-block" }, [
        _c("div", { staticClass: "wn mc" }, [
          _c("div", { staticClass: "item intro" }, [
            _c("div", { staticClass: "ac intro-title intro-title2" }, [
              _vm._v("我们的服务")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item-boxes services-item" }, [
              _c("i", { staticClass: "ico2 ico2-1" }),
              _vm._v(" "),
              _c("h4", [_vm._v("人工智能")]),
              _vm._v(" "),
              _c("p", [_vm._v("数据更智能、业务才更智能")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item-boxes services-item" }, [
              _c("i", { staticClass: "ico2 ico2-3" }),
              _vm._v(" "),
              _c("h4", [_vm._v("金融科技")]),
              _vm._v(" "),
              _c("p", [_vm._v("擅长金融领域科技研发与测试")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item-boxes services-item" }, [
              _c("i", { staticClass: "ico2 ico2-2" }),
              _vm._v(" "),
              _c("h4", [_vm._v("大数据")]),
              _vm._v(" "),
              _c("p", [_vm._v("海量数据分析和挖掘能力")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item-boxes services-item" }, [
              _c("i", { staticClass: "ico2 ico2-4" }),
              _vm._v(" "),
              _c("h4", [_vm._v("云计算")]),
              _vm._v(" "),
              _c("p", [_vm._v("高灵活性、可扩展性和高性价比")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item-boxes services-item" }, [
              _c("i", { staticClass: "ico2 ico2-5" }),
              _vm._v(" "),
              _c("h4", [_vm._v("软件开发")]),
              _vm._v(" "),
              _c("p", [_vm._v("技术领先、一站式软件定制开发服务")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item-boxes services-item" }, [
              _c("i", { staticClass: "ico2 ico2-6" }),
              _vm._v(" "),
              _c("h4", [_vm._v("数据安全")]),
              _vm._v(" "),
              _c("p", [_vm._v("数据加密，安全可靠")])
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }